import { Api } from "..";
import { Network } from "../../types/network";
import { INetworkToken, IToken } from "./types";

class TokensApi extends Api {
    async getTokens() {
        return this.api.get('/tokens').then(res => res.data.tokens as (Omit<IToken, "networks"> & {networks: Network[], supply: string})[])
    }

    async getToken(id: string) {
        return this.api.get('/tokens/'+id).then(res => res.data as IToken & {networks: INetworkToken[]})
    }

    async createToken(name: string, symbol: string, image: string) {
        const token = await this.api.post('/tokens', {
            name,
            symbol,
            image,
        }).then(res => res.data.token as (Omit<IToken, "networks"> & {networks: Network[]}));
        gtag("event", "create_token")
        return token;
    }

    async estimateGas(name: string, symbol: string, image: string) {
        return this.api.post('/tokens', {
            name,
            symbol,
            image,
        }).then(res => res.data.token as (Omit<IToken, "networks"> & {networks: Network[]}));
    }

    async setNotifyUrl(token: string, notifyUrl: string) {
        return this.api.put('/tokens/'+token+'/notifyUrl', {
            url: notifyUrl,
        }).then(res => res.data.token as (Omit<IToken, "networks"> & {networks: Network[]}));
    }

    async resetKeys(token: string) {
        return this.api.post('/tokens/'+token+'/apiReset').then(res => res.data.token as (Omit<IToken, "networks"> & {networks: Network[]}));
    }
}

export const tokensApi = new TokensApi();