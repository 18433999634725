import { IToken } from '../api/tokens/types'
import { metamaskNetworkConfig, networkChainIds } from '../constants/networks'
import { Network } from '../types/network'

export const switchToNetwork = async (network: Network) => {
  const provider = window.ethereum
  if (provider) {
    try {
      gtag('event', 'switch_network', { network })
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x' + networkChainIds[network].toString(16) }],
      })
    } catch (error: any) {
      console.log(error)
      if (error.code === 4902) {
        await provider
          .request({
            method: 'wallet_addEthereumChain',
            params: [metamaskNetworkConfig[network]],
          })
          .then(() => switchToNetwork(network))
      }
    }
  }
}

export const hasEthereum = () => {
  return !!window.ethereum
}

export const addToMetamask = async (network: Network, token: IToken, address: string) => {
  try {
    const provider = window.ethereum
    if (provider) {
      const currentChainId = await provider.request({ method: 'eth_chainId' }) // ex. 0x89
      const currentChainIdDecimal = parseInt(currentChainId, 16) // ex. 137
      const currentNetwork = Object.keys(networkChainIds).find((key) => networkChainIds[key as Network] === currentChainIdDecimal) as Network
      if (currentNetwork !== network) {
        await switchToNetwork(network)
      }
      
      const added: boolean = await provider.request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20',
            options: {
              address,
              symbol: token.symbol,
              decimals: 6,
              image: token.image,
            },
          },
        })

      gtag('event', 'add_token_to_metamask', { network, token: token.symbol, added })
    
      return added
    } else {
      window.open(`https://metamask.io/download/`, '_blank')!.onclose = () => addToMetamask(network, token, address)
    }
  } catch (error: any) {
    console.log(error)
    if (error.code === 4001) {
        return false
    }
  }
}
