import { setToken } from "../utils/tokens";
import { Api } from ".";

class AuthApi extends Api {
    async signUp(email: string, password: string, name: string, phone: string) {
        const data = await this.baseApi.post('/auth', {
            email,
            password,
            name,
            phone
        }).then(res => res.data as { token: string, refreshToken: string })
        gtag("event", "sign_up")
        setToken('client', data.token);
        setToken('refresh', data.refreshToken);
        return data;
    }

    async signIn(email: string, password: string) {
        const data = await this.baseApi.post('/auth/login', {
            email,
            password
        }).then(res => res.data as { token: string, refreshToken: string });
        gtag("event", "login")
        setToken('client', data.token);
        setToken('refresh', data.refreshToken);
        return data;
    }
}

export const authApi = new AuthApi();